import React from 'react';
import logoSvg from './logo.svg';
import alertsSvg from './alerts.svg';
import reportsSvg from './reports.svg';
import journeyTime from './journeytime.png';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img src={logoSvg} className={styles.logo} alt="Insight" /> Insight
      </header>
      <div className={styles.sections}>
        <a href="https://www.insighthub.io/insight/journeytimes/routes" className={styles.section}>
          <div className={styles.image}>
            <img src={journeyTime} alt="Journey Time screen example" />
          </div>
          <p>Journey Times</p>
        </a>
        <a href="https://www.insighthub.io/insight/alert/history" className={styles.section}>
          <div className={styles.imageSvg}>
            <img src={alertsSvg} alt="Alerts screen example" />
          </div>
          <p>Alerts</p>
        </a>
        <a href="https://www.insighthub.io/insight/reports/list?category=journeytime" className={styles.section}>
          <div className={styles.imageSvg}>
            <img src={reportsSvg} alt="Reports screen example" />
          </div>
          <p>Reports</p>
        </a>
      </div>
    </div>
  );
}

export default App;
